import React from 'react'
import * as productCodes from '@/constants/product-codes'
import ProductAccordion from '@/components/ProductAccordion'
import { Button } from '@saatva-bits/pattern-library.components.button'
import TabbedContentDisplay from '@/components/TabbedContentDisplay'
import MattressFaceOff from '@/components/MattressFaceOff'
import styles from './ImageByContent.module.scss'
import { SvgSprite } from '@saatva-bits/pattern-library.components.svg-sprite'

const config = {
    [`${productCodes.LOOM_AND_LEAF}`]: {
        title: `Your best sleep starts with the best memory foam mattress`,
        content: () => [
            'Rest deeply with our ultra-premium memory foam mattress that strikes the perfect balance of comfort, support, and luxury.'
        ],
        imageFolder: 'generic/bed-woman',
        imageName: 'bed-woman-1-1.jpg',
        imageAlt: 'Woman sits comfortably on the memory foam mattress'
    },
    [`${productCodes.SAATVA_CONTOUR5}`]: {
        title: `The world’s most luxurious memory foam mattress`,
        content: () => [
            'At the heart of Contour5 is three plush inches of our ultra-premium 5lb memory foam for indulgent body-hugging comfort that can’t be replicated by a bed in a box.'
        ],
        imageFolder: 'generic/bed-woman',
        imageName: 'bed-woman-1-1.jpg',
        imageAlt: 'Woman sits comfortably on the memory foam mattress'
    },
    [`${productCodes.SAATVA_CONTOUR5}-coolVent`]: {
        title: `Stay comfy and rested with our CoolVent™ System`,
        content: () => [
            <ProductAccordion contentKey={productCodes.SAATVA_CONTOUR5} key="coolVentAccordion" classNameModifier={'bgContrast1'} className={styles.tanAccordion} />
        ],
        imageFolder: 'generic/saatva-contour5-closer-look',
        imageName: 'saatva-contour5-closer-look-1-1.jpg',
        imageAlt: 'Mattress layers of our CoolVent system'
    },
    [`${productCodes.LOOM_AND_LEAF}--memory-foam`]: {
        title: `A memory foam mattress that's better for our planet`,
        content: () => [
            'When we say Loom & Leaf is an ultra-premium memory foam mattress, we don’t just stop at luxurious craftsmanship and comfort. We make sure it’s as good for our environment as it is for your sleep.',
            <ProductAccordion contentKey={productCodes.LOOM_AND_LEAF} key="memoryFoamAccordion" classNameModifier={'bgContrast1'} />
        ],
        imageFolder: 'generic/hand-memory-foam',
        imageName: 'hand-memory-foam-1-1.jpg',
        imageAlt: 'A hand touching a memory foam mattress'
    },
    [`${productCodes.ZENHAVEN}`]: {
        title: `Pure comfort starts with pure materials`,
        hasMultipleImages: true,
        content: TabbedContentDisplay,
        imageFolder: [
            'generic/pure-comfort-organic',
            'generic/pure-comfort-natural',
            'generic/pure-comfort-nontoxic'
        ],
        imageName: [
            'pure-comfort-organic-1-1.jpg',
            'pure-comfort-natural-1-1.jpg',
            'pure-comfort-nontoxic-1-1.jpg'
        ],
        imageAlt: 'A pure comfort generic image'
    },
    [`${productCodes.SAATVA_LATEX_HYBRID}`]: {
        title: `Pure comfort starts with pure materials`,
        hasMultipleImages: true,
        content: TabbedContentDisplay,
        imageFolder: [
            'generic/pure-comfort-organic',
            'generic/pure-comfort-natural',
            'generic/pure-comfort-nontoxic'
        ],
        imageName: [
            'pure-comfort-organic-1-1.jpg',
            'pure-comfort-natural-1-1.jpg',
            'pure-comfort-nontoxic-1-1.jpg'
        ],
        imageAlt: 'A pure comfort generic image'
    },
    [`drift-away`]: {
        title: `Drift away on layers of pure, organic natural latex foam`,
        content: () => [
            'Our eco-friendly, ultra-cooling premium latex mattress features a proprietary design for the most buoyant feel imaginable.'
        ],
        imageFolder: 'generic/overview-intro',
        imageName: 'overview-intro-1-1.jpg',
        imageAlt: 'Woman sits comfortably on the memory foam mattress'
    },
    [`made-to-order`]: {
        title: 'Made to order, never&nbsp;stuffed&nbsp;in&nbsp;a box',
        content: () => [
            `Every Saatva mattress is handcrafted and assembled when you order it. Unlike other internet mattresses, we'd never dream of compressing our premium beds into a small box. Doing so can reduce its lifespan and compromise your comfort.`,
            <Button key='madeToOrderButton' kind='primary' className='u-paddingHorizontal--2dot5xl' href='https://pages.saatva.com/saatva-vs-bed-in-a-box' target='_blank'>See why we’re not a bed in a box</Button>
        ],
        imageFolder: 'generic/factory-mattress',
        imageName: 'factory-mattress-1-1.jpg',
        imageAlt: 'Woman sewing a mattress'
    },
    [`${productCodes.BUNK_TRUNDLE_YOUTH}`]: {
        title: 'Upgrade your child’s or guest room like never before',
        content: () => ['Unlike bunk bed mattresses made of cheap foam with minimal support, ours boasts all the comfort features of a luxury Saatva mattress at just 6” thick. Handcrafted, never stuffed in a box, delivered free to your room.'],
        imageFolder: 'products/bunk-trundle-youth/bunk-trundle-hero',
        imageName: 'bunk-trundle-hero-1-1.jpg',
        imageAlt: 'Child on Saatva Bunk and Trundle mattress'
    },
    [`${productCodes.SAATVA_YOUTH}`]: {
        title: 'Great days start with great sleep on the best mattress for kids',
        content: () => ['Our dual-sided youth mattress is specifically designed to help growing kids ages 3 to 12 reach their full potential through great sleep.'],
        imageFolder: 'products/saatva-youth/girl',
        imageName: 'saatva-youth-girl-1-1.jpg',
        imageAlt: 'Child on Saatva Youth mattress'
    },
    [`${productCodes.CRIB_MATTRESS}`]: {
        title: 'Give a bundle of comfort to your bundle of joy with the best crib mattress',
        content: () => ['Our handcrafted crib mattress is designed with the safety and unique sleep needs of babies and toddlers in mind.'],
        imageFolder: 'products/crib-mattress/hero',
        imageName: 'saatva-crib-hero-1-1.jpg',
        imageAlt: 'Crib Mattress hero image'
    },
    [`${productCodes.ZENHAVEN}--why-natural`]: {
        title: `Why natural latex?`,
        content: () => [
            'There’s nothing quite like the buoyant, pressure-free support of natural latex. Our latex goes through a washing process to remove all allergy-causing proteins, so all you’re left with is pure, lasting comfort.',
            <ProductAccordion contentKey={productCodes.ZENHAVEN} key="memoryFoamAccordion" />
        ],
        imageFolder: 'generic/why-natural-latex',
        imageName: 'why-natural-latex-1-1.jpg',
        imageAlt: 'Natural latex mattress material'
    },
    [`${productCodes.SAATVA_LATEX_HYBRID}--why-natural`]: {
        title: `Why natural latex?`,
        content: () => [
            'There’s nothing quite like the buoyant, pressure-free support of natural latex. Our latex goes through a washing process to remove all allergy-causing proteins, so all you’re left with is pure, lasting comfort.',
            <ProductAccordion contentKey={productCodes.SAATVA_LATEX_HYBRID} key="memoryFoamAccordion" />
        ],
        imageFolder: 'generic/why-natural-latex',
        imageName: 'why-natural-latex-1-1.jpg',
        imageAlt: 'Natural latex mattress material'
    },
    [`${productCodes.SAATVA_LATEX_HYBRID}--savor-healthier`]: {
        title: `Savor healthier, cooler sleep with organic natural latex foam`,
        content: () => [
            'Our eco-friendly, ultra-cooling latex hybrid  combines the buoyant feel of natural latex coupled with the responsive support of an innerspring.'
        ],
        imageFolder: 'products/saatva-latex-hybrid/above',
        imageName: 'saatva-latex-hybrid-above-1-1.jpg',
        imageAlt: 'Latex Hybrid savor-healthier'
    },
    [`${productCodes.CRIB_MATTRESS}--why-natural`]: {
        title: `Why natural latex?`,
        content: () => [
            'There’s nothing quite like the buoyant, pressure-free support of natural latex. Our latex goes through a washing process to remove all allergy-causing proteins, so all you’re left with is pure, lasting comfort.',
            <ProductAccordion contentKey={productCodes.CRIB_MATTRESS} key="cribLatexAccordion" />
        ],
        imageFolder: 'generic/why-natural-latex',
        imageName: 'why-natural-latex-1-1.jpg',
        imageAlt: 'Natural latex mattress material'
    },
    [`${productCodes.SAATVA_HD}--why-natural`]: {
        title: `Why natural latex?`,
        content: () => [
            'There’s nothing quite like the buoyant, pressure-free support of natural latex. Our latex goes through a washing process to remove all allergy-causing proteins, so all you’re left with is pure, lasting comfort.',
            <ProductAccordion contentKey={productCodes.SAATVA_HD} key="memoryFoamAccordion" />
        ],
        imageFolder: 'generic/why-natural-latex',
        imageName: 'why-natural-latex-1-1.jpg',
        imageAlt: 'Natural latex mattress material'
    },
    [`${productCodes.SAATVA_HD}`]: {
        title: `The best mattress for heavier bodies`,
        content: () => [
            'Designed to support sleepers up to 500 pounds, our proprietary heavy-duty mattress balances luxurious comfort with unrivaled durability for every body type.'
        ],
        imageFolder: 'products/saatva-hd/above',
        imageName: 'saatva-hd-above-1-1.jpg',
        imageAlt: 'Saatva HD Mattress'
    },
    [productCodes.ORGANIC_COTTON_PILLOWCASES]: {
        title: `Indulge in the luxury of sateen cotton`,
        content: () => [
            'Woven in a one-yarn-over, three-yarn-under pattern with a silky 300 thread count, sateen feels heavenly soft against the skin.',
        ],
        imageFolder: 'generic/material-sateen',
        imageName: 'material-sateen-1-1.jpg',
        imageAlt: 'Organic Sateen Pillowcase Pair'
    },
    [productCodes.PERCALE_PILLOWCASES]: {
        title: `Keep it cool in percale cotton`,
        content: () => [
            'Woven in a classic one-yarn-over, one-yarn-under pattern with an ultra-breathable 200 thread count, percale feels as smooth and crisp as your favorite button down shirt—only better.',
        ],
        imageFolder: 'generic/material-percale',
        imageName: 'material-percale-1-1.jpg',
        imageAlt: 'Percale Pillowcase Pair'
    },
    [productCodes.SATEEN_COTTON_PILLOWCASES]: {
        title: `Indulge in the luxury of sateen cotton`,
        content: () => [
            'Woven in a one-yarn-over, three-yarn-under pattern with a silky 300 thread count, sateen feels heavenly smooth against the skin.',
        ],
        imageFolder: 'generic/material-sateen',
        imageName: 'material-sateen-1-1.jpg',
        imageAlt: 'Signature Sateen Pillowcase Pair'
    },
    [productCodes.BANDED_PERCALE_PILLOWCASES]: {
        title: `Keep it cool in percale cotton`,
        content: () => [
            'Woven in a classic one-yarn-over, one-yarn-under pattern with an ultra-breathable 200 thread count, percale feels as smooth and crisp as your favorite button down shirt—only better.',
        ],
        imageFolder: 'generic/material-percale',
        imageName: 'material-percale-1-1.jpg',
        imageAlt: 'Banded Percale Pillowcase Pair'
    },
    [productCodes.LINEN_PILLOWCASES]: {
        title: `Upgrade to the distinctive comfort of linen`,
        content: () => [
            'Our 100% European flax linen is yarn dyed and prewashed for softness that only gets better with time.',
        ],
        imageFolder: 'generic/material-linen',
        imageName: 'material-linen-1-1.jpg',
        imageAlt: 'Linen Pillowcase Pair'
    },
    ['why-is-linen-worth-the-splurge']: {
        title: 'Why linen bedding is worth the splurge',
        content: () => [
            'Although linen can cost more initially, here are a few reasons why it’s a luxurious alternative to cotton sheets.',
            <ProductAccordion contentKey='why-is-linen-worth-the-splurge' classNameModifier={'bgContrast2'} className='u-bgColor--contrast-2' key="linenSplurgeAccordion" />
        ],
        imageFolder: 'products/linen-pillowcases/lifestyle',
        imageName: 'linen-pillowcases-lifestyle-1-1.jpg',
        imageAlt: 'Linen Pillowcase Pair on a Made Bed'
    },
    [productCodes.ESSENTIAL_PILLOWCASES]: {
        title: `Breathable cotton-viscose blend`,
        content: () => [
            'This exceptionally soft and breathable fabric blend is perfect for hot sleepers.',
        ],
        imageFolder: 'generic/material-essential',
        imageName: 'material-essential-1-1.jpg',
        imageAlt: 'Essential Luxury Pillowcase Pair'
    },
    [productCodes.FLANNEL_PILLOWCASES]: {
        title: `Velvety-soft cotton flannel`,
        content: () => [
            'Brushed and yarn-dyed for ultimate softness, our luxury flannel offers substantial coziness without the weight.',
        ],
        imageFolder: 'generic/material-flannel',
        imageName: 'material-flannel-1-1.jpg',
        imageAlt: 'Luxury Flannel Pillowcase Pair'
    },
    [productCodes.EMBROIDERED_PILLOWCASES]: {
        title: `Indulge in the luxury of sateen cotton`,
        content: () => [
            'Woven in a one-yarn-over, three-yarn-under pattern with a silky 300 thread count, sateen feels heavenly smooth against the skin.',
        ],
        imageFolder: 'generic/material-sateen',
        imageName: 'material-sateen-1-1.jpg',
        imageAlt: 'Embroidered Sateen Pillowcase Pair'
    },
    [`${productCodes.THE_SAATVA_PILLOW}--why-natural`]: {
        title: `Why natural latex?`,
        content: () => [
            'There’s nothing quite like the buoyant, pressure-free support of natural latex. Our latex goes through a washing process to remove all allergy-causing proteins, so all you’re left with is pure, lasting comfort.',
            <ProductAccordion contentKey={productCodes.THE_SAATVA_PILLOW} key="memoryFoamAccordion" />
        ],
        imageFolder: 'generic/why-natural-latex',
        imageName: 'why-natural-latex-1-1.jpg',
        imageAlt: 'Natural latex mattress material'
    },
    [`why-natural-latex`]: {
        title: `Why natural latex?`,
        content: () => [
            'There’s nothing quite like the buoyant, pressure-free support of natural latex. Our latex goes through a washing process to remove all allergy-causing proteins, so all you’re left with is pure, lasting comfort.',
            <ProductAccordion contentKey='natural-latex' key="memoryFoamAccordion" classNameModifier={'bgContrast1'} className='u-bgColor--contrast-1' />
        ],
        imageFolder: 'generic/why-natural-latex',
        imageName: 'why-natural-latex-1-1.jpg',
        imageAlt: 'Natural latex mattress material'
    },
    [`${productCodes.ORGANIC_QUILTED_PILLOW}--why-natural`]: {
        title: `Why natural latex?`,
        content: () => [
            'There’s nothing quite like the buoyant, pressure-free support of natural latex. Our latex goes through a washing process to remove all allergy-causing proteins, so all you’re left with is pure, lasting comfort.',
            <ProductAccordion contentKey={productCodes.ORGANIC_QUILTED_PILLOW} key="memoryFoamAccordion" />
        ],
        imageFolder: 'generic/why-natural-latex',
        imageName: 'why-natural-latex-1-1.jpg',
        imageAlt: 'Natural latex mattress material'
    },
    [`${productCodes.FEATHERBED_MATTRESS_TOPPER}`]: {
        title: 'Ultra-plush 3D down alternative & lyocell fill',
        content: () => [
            'This airy blend is naturally breathable, moisture-wicking and exceptionally-soft, making you feel like you’re sleeping on a cloud.'
        ],
        imageFolder: 'products/featherbed-mattress-topper/silo-close-up',
        imageName: 'featherbed-mattress-topper-silo-close-up-1-1.jpg',
        imageAlt: 'Featherbed Mattress Topper'
    },
    [`${productCodes.GRAPHITE_MEMORY_FOAM_MATTRESS_TOPPER}`]: {
        title: 'Body hugging memory foam infused with cooling graphite',
        content: () => [
            'Contours to every curve for total pressure relief, leading to more restful sleep.'
        ],
        imageFolder: 'products/graphite-memory-foam-mattress-topper/lifestyle-close-up',
        imageName: 'graphite-memory-foam-mattress-topper-lifestyle-close-up-1-1.jpg',
        imageAlt: 'Graphite Memory Foam Mattress Topper'
    },
    [`${productCodes.HIGH_DENSITY_FOAM_MATTRESS_TOPPER}`]: {
        title: 'High-density foam for an instant boost of support',
        content: () => [
            'Loved for its durability and body-contouring feel, this topper offers premium comfort at a comfortable price.'
        ],
        imageFolder: 'products/high-density-foam-mattress-topper/lifestyle-corner',
        imageName: 'high-density-foam-mattress-topper-lifestyle-corner-1-1.jpg',
        imageAlt: 'High Density Foam Mattress Topper'
    },
    [`${productCodes.MICROCOIL_MATTRESS_TOPPER}`]: {
        title: 'Micro-coils with zoned support',
        content: () => [
            'Our responsive micro-coil layer offers extra support in the lumbar area and more cushioning around the pressure points for all-night comfort.'
        ],
        imageFolder: 'products/microcoil-mattress-topper/lifestyle-corner',
        imageName: 'microcoil-mattress-topper-lifestyle-corner-1-1.jpg',
        imageAlt: 'Micro-coil Mattress Topper'
    },
    [`${productCodes.SCENTED_CANDLES}`]: {
        title: `Explore the aromatherapy benefits of our signature scented candles`,
        content: () => [
            <ProductAccordion contentKey={productCodes.SCENTED_CANDLES} key="scentedCandlesAccordion" />
        ],
        imageFolder: 'products/scented-candles/lifestyle',
        imageName: 'scented-candles-lifestyle-1-1.jpg',
        imageAlt: 'Scented Candles'
    },
    [`${productCodes.SCENTED_VOTIVES}`]: {
        title: `Explore the aromatherapy benefits of our signature scented candles`,
        content: () => [
            <ProductAccordion contentKey={productCodes.SCENTED_VOTIVES} key="scentedVotivesAccordion" />
        ],
        imageFolder: 'products/scented-votives/lifestyle',
        imageName: 'scented-votives-lifestyle-1-1.jpg',
        imageAlt: 'Scented Votives'
    },
    [`${productCodes.FOUNDATION}`]: {
        title: 'Upgrade your bed setup with our premium foundation or bunkie board',
        content: () => [
            'Designed to be an elevated take on traditional box springs and bunkie boards, our foundations and 2” bunkie board maximize the comfort and lifespan of your mattress.'
        ],
        imageFolder: 'generic/bed-woman-2',
        imageName: 'bed-woman-2-1-1.jpg',
        imageAlt: 'Woman sitting on bed'
    },
    [`${productCodes.SAATVA_RX}`]: {
        title: 'The best mattress for chronic back & joint conditions',
        content: () => [
            'Rx offers therapeutic support and unparalleled pressure relief with an innovative support core that cradles your body in weightless comfort.'
        ],
        imageFolder: 'products/saatva-rx/above',
        imageName: 'saatva-rx-above-1-1.jpg',
        imageAlt: 'Saatva Rx Mattress'
    },
    [`${productCodes.SOLAIRE}`]: {
        title: 'Discover the power of personalized sleep comfort',
        content: () => [
            'Why settle when you can customize your firmness with a push of a button? Premium cooling layers help you further ease into luxurious comfort.'
        ],
        imageFolder: 'products/solaire/room-angle-raised/upper-flex',
        imageName: 'solaire-room-angle-raised-upper-flex-1-1.jpg',
        imageAlt: 'Solaire Upper-Flex Mattress'
    },
    [`${productCodes.SOLAIRE}--consumer-reports`]: {
        title: 'Mattress Face-Off:\nSaatva\u00A0vs.\u00A0Sleep Number',
        content: () => [
            <MattressFaceOff key='solaire-consumer-reports' />
        ],
        imageFolder: 'generic/solaire-consumer-reports',
        imageName: 'solaire-consumer-reports-1-1.png',
        imageAlt: 'Mattress Face-Off: Saatva vs. Sleep Number'
    },
    [`${productCodes.SOLAIRE}--personalized-comfort`]: {
        title: `Get even more personalized comfort options with our Saatva Adjustable Base Plus`,
        hasMultipleImages: true,
        content: TabbedContentDisplay,
        imageFolder: [
            'products/saatva-classic/side',
            'products/adjustable-base-plus/couple'
        ],
        imageName: [
            'saatva-classic-side-1-1.jpg',
            'adjustable-base-plus-couple-1-1.jpg'
        ],
        imageAlt: 'Images of the Solaire mattress with our Saatva Adjustable Base Plus'
    },
    [`${productCodes.SOLAIRE}--ultimate-sleep-comfort`]: {
        title: `Your ultimate sleep comfort at your fingertips`,
        content: () => [
            <p key='remote-paragraph'>Get your most effortless sleep night after night with just a touch of the remote (included for both sides).</p>,
            <p key='link-paragraph'><a className='t-link' href='https://www.saatva.com/blog/solaire-adjustable-firmness-mattress/' rel='noopener noreferrer'><SvgSprite spriteID='icon-article-link' className={styles.articleLink} />What to Expect After Buying a Solaire Adjustable Firmness Mattress</a></p>,
            <ProductAccordion contentKey={productCodes.SOLAIRE} key="solaireSleepComfort" classNameModifier={'bgContrast2'} className='u-bgColor--contrast-2' />
        ],
        imageFolder: 'products/solaire/remote-features',
        imageName: 'solaire-remote-features-1-1.jpg',
        imageAlt: 'A person holding a Solaire mattress remote'
    },
    'therapeutic-support-core': {
        title: 'Maximum relief starts at the core',
        content: () => [
            'A closer look inside our groundbreaking Therapeutic Support Core™',
            <ProductAccordion contentKey={productCodes.SAATVA_RX} key="therapeuticSupportAccordion" className={styles.tanAccordion} />
        ],
        imageFolder: 'generic/support-core-closer-look',
        imageName: 'support-core-closer-look-1-1.jpg',
        imageAlt: 'Triple-phase LuxeCool™ system'
    },
    [`${productCodes.DOG_BED}--microcoil`]: {
        title: 'Micro coils, big comfort',
        content: () => [
            `To build a dog bed like no other, we started at the core.`,
            <p key="microcoil1">Our durable <span className="t-fontWeight--normal">micro-coil support</span> core helps keep your pet’s back happy, keeps them cooler, and makes moving around in bed much easier.</p>,
            <p key="microcoil2" className="t-italic t-bodySm">*For dogs that are heavy chewers, keep an eye on the bed cover’s condition to ensure they don’t chew down to internal parts and to prevent damage to the bed's core.</p>
        ],
        imageFolder: 'products/dog-bed/colorway-angle',
        imageName: 'dog-bed-colorway-angle-1-1.jpg',
        imageAlt: 'Saatva Dog Bed micro-coil support'
    },
    [`${productCodes.DOG_BED}--cozy`]: {
        title: 'Cozy, cuddly & easy to clean',
        content: () => [
            <p key="cozy0">Your pet’s bed can look <span className="t-italic">ruff</span> after a while. We made our ultra-luxe, <span className="t-fontWeight--normal">high-performance fabric cover</span> resistant to spills and stains, so cleanup is a breeze.</p>,
            <p key="cozy1">Need a backup? We have <button className={styles.linkButton} onClick={() => window.scrollTo(0, 0)}>replacement bed covers</button> available.</p>
        ],
        imageFolder: 'products/dog-bed/cover/taupe',
        imageName: 'dog-bed-cover-taupe-1-1.jpg',
        imageAlt: 'Saatva Dog Bed Covers'
    },
    [`${productCodes.DOG_BED}--layer`]: {
        title: 'The comfort your pet deserves, built into every layer',
        content: () => [
            <ProductAccordion contentKey={productCodes.DOG_BED} key="dogBedAccordion" className={styles.tanAccordion} />
        ],
        imageFolder: 'products/dog-bed/cutaway-numbered',
        imageName: 'dog-bed-cutaway-numbered-1-1.jpg',
        imageAlt: 'Saatva Dog Bed Layers'
    },
    [`${productCodes.SAATVA_CLASSIC}`]: {
        title: 'Enjoy blissful sleep on a tried-and-true favorite',
        content: () => [
            'Our award-winning innerspring offers dreamy comfort inspired by high-end luxury hotels with responsive support for every sleeper.'
        ],
        imageFolder: 'products/saatva-classic/above',
        imageName: 'saatva-classic-above-1-1.jpg',
        imageAlt: 'Saatva Classic Mattress'
    },
    [`${productCodes.MEMORY_FOAM_HYBRID}`]: {
        title: `A memory foam hybrid that's the best of both worlds`,
        content: () => [
            'Enjoy the memory foam feel you love with responsive innerspring support and innovative cooling for truly restorative sleep.'
        ],
        imageFolder: 'products/memory-foam-hybrid/above',
        imageName: 'memory-foam-hybrid-above-1-1.jpg',
        imageAlt: 'Memory Foam Hybrid Mattress'
    },
    'triple-phase': {
        title: 'Sleep cooler with our triple-phase LuxeCool™ system',
        content: () => [
            <ProductAccordion contentKey={productCodes.MEMORY_FOAM_HYBRID} key="therapeuticSupportAccordion" className={styles.tanAccordion} />
        ],
        imageFolder: 'products/memory-foam-hybrid/cutaway',
        imageName: 'memory-foam-hybrid-cutaway-1-1.jpg',
        imageAlt: 'Triple-phase LuxeCool™ system'
    },
    'higher-quality-leather': {
        title: 'Higher standards for higher quality leather',
        content: () => [
            <ProductAccordion contentKey={'higher-quality-leather'} key="higherQualityLeatherAccordion" />
        ],
        imageFolder: 'generic/materials-quality-leather',
        imageName: 'materials-quality-leather-1-1.jpg',
        imageAlt: 'Higher standards for higher quality leather'
    },
    [`${productCodes.CLASSIC_STARTER_BUNDLE}`]: {
        title: 'Better together',
        content: () => [
            'Our best-selling Saatva Classic is a standout on its own, but even better paired with our most popular add ons that enhance support and everyday resilience.'
        ],
        imageFolder: 'products/classic-starter-bundle/room-above',
        imageName: 'classic-starter-bundle-room-above-1-1.jpg',
        imageAlt: 'Image with the items bundled'
    },
    [`${productCodes.CLASSIC_STARTER_BUNDLE}--your-best-sleep`]: {
        title: ` Your best sleep starts here`,
        content: () => [
            'The Classic Bundle is ideal for:',
            <ProductAccordion contentKey={productCodes.CLASSIC_STARTER_BUNDLE} key="classicStarterBundleAccordion" classNameModifier='bgContrast2' className='u-bgColor--contrast-2' />
        ],
        imageFolder: 'generic/your-best-sleep',
        imageName: 'classic-bundle-pdp-best-sleep-1-1.jpg',
        imageAlt: 'your best sleep with out classic starter bundle'
    },
    [productCodes.WEIGHTED_SILK_EYE_MASK]: {
        title: 'How does a weighted eye mask help improve your sleep?',
        content: () => [
            <ProductAccordion contentKey={`${productCodes.WEIGHTED_SILK_EYE_MASK}`} classNameModifier={'bgContrast2'} className='u-bgColor--contrast-2' key="weightedSilkEyeMaskAccordion" />
        ],
        imageFolder: 'products/weighted-silk-eye-mask/lifestyle-messy',
        imageName: 'weighted-silk-eye-mask-lifestyle-messy-1-1.jpg',
        imageAlt: 'Weighted Silk Eye Masks'
    },
    [productCodes.SILK_EYE_MASK]: {
        title: 'How does an eye mask help improve your sleep?',
        content: () => [
            <ProductAccordion contentKey={`${productCodes.SILK_EYE_MASK}`} classNameModifier={'bgContrast2'} className='u-bgColor--contrast-2' key="silkEyeMaskAccordion" />
        ],
        imageFolder: 'products/silk-eye-mask/lifestyle-messy',
        imageName: 'silk-eye-mask-lifestyle-messy-1-1.jpg',
        imageAlt: 'Silk Eye Masks'
    },
    [productCodes.WAFFLE_TOWELS]: {
        title: 'Airy, soft, and exceptionally quick drying',
        content: () => [
            'Our Waffle Towel Collection is woven with durable long staple cotton in a traditional Imabari-style waffle design. This creates a larger surface area for maximum absorbency while maintaining great airflow.'
        ],
        imageFolder: 'products/waffle-towels/lifestyle',
        imageName: 'waffle-towels-lifestyle-1-1.jpg',
        imageAlt: 'Waffle towels hanging on a rack'
    },
    [productCodes.PLUSH_TOWELS]: {
        title: 'Luxury hotel plushness in your own bath',
        content: () => [
            'Our Plush Towel Collection is woven exclusively with extra-long terry cotton for a thick, absorbent, and sublimely soft feel. It’s everyday luxury at its finest.'
        ],
        imageFolder: 'products/plush-towels/lifestyle',
        imageName: 'plush-towels-lifestyle-1-1.jpg',
        imageAlt: 'Plush towels stacked on a stool'
    },
    [`${productCodes.KANAN_BEDROOM_RUG}`]: {
        title: 'Expertly handcrafted for lasting comfort & luxury',
        content: () => ['Handwoven individually by skilled artisans using a traditional hand-operated loom technique, this rug is crafted to look and feel great for years to come.'],
        imageFolder: 'products/kanan-bedroom-rug/colorways',
        imageName: 'kanan-bedroom-rug-colorways-1-1.jpg',
        imageAlt: 'Close-up of overlapping rugs'
    },
    [`${productCodes.TERNA_BEDROOM_RUG}`]: {
        title: 'Expertly handcrafted for lasting comfort & luxury',
        content: () => ['Hand tufted by skilled artisans to create its stunning pattern and plush feel, the Terna is the ultimate blend of quality and a great price point.'],
        imageFolder: 'products/terna-bedroom-rug/colorways',
        imageName: 'terna-bedroom-rug-colorways-1-1.jpg',
        imageAlt: 'Close-up of overlapping rugs'
    },
    [`${productCodes.ADAMAS_BEDROOM_RUG}`]: {
        title: 'Expertly handcrafted for lasting comfort & luxury',
        content: () => ['Handwoven individually by skilled artisans using a traditional hand-operated loom technique, this rug is crafted to look and feel great for years to come.'],
        imageFolder: 'products/adamas-bedroom-rug/colorways',
        imageName: 'adamas-bedroom-rug-colorways-1-1.jpg',
        imageAlt: 'Close-up of overlapping rugs'
    },
    [`${productCodes.CIRRUS_BEDROOM_RUG}`]: {
        title: 'Expertly handcrafted for lasting comfort & luxury',
        content: () => ['Handwoven individually by skilled artisans using a traditional hand-operated loom technique, this rug is crafted to look and feel great for years to come.'],
        imageFolder: 'products/cirrus-bedroom-rug/colorways',
        imageName: 'cirrus-bedroom-rug-colorways-1-1.jpg',
        imageAlt: 'Close-up of overlapping rugs'
    },
    [`${productCodes.MYRA_BEDROOM_RUG}`]: {
        title: 'Expertly handcrafted for lasting comfort & luxury',
        content: () => ['Handwoven individually by skilled artisans using a traditional hand-operated loom technique, this rug is crafted to look and feel great for years to come.'],
        imageFolder: 'products/myra-bedroom-rug/colorways',
        imageName: 'myra-bedroom-rug-colorways-1-1.jpg',
        imageAlt: 'Close-up of overlapping rugs'
    },
    [`${productCodes.NAVI_BEDROOM_RUG}`]: {
        title: 'Expertly handcrafted for lasting comfort & luxury',
        content: () => ['Handwoven individually by skilled artisans using a traditional hand-operated loom technique, this rug is crafted to look and feel great for years to come.'],
        imageFolder: 'products/navi-bedroom-rug/colorways',
        imageName: 'navi-bedroom-rug-colorways-1-1.jpg',
        imageAlt: 'Close-up of overlapping rugs'
    },
    [`${productCodes.THE_SAATVA_PILLOW}`]: {
        title: 'Which pillow height is right for you?',
        content: () => [
            <span key="standardLoft" className="t-fontWeight--normal">Standard Loft</span>,
            (<ul key="standardLoftList" className={styles.discList}>
                <li>Roughly 5” high</li>
                <li>Lower height preferred by back & stomach sleepers or anyone who prefers a lower pillow</li>
            </ul>),
            <span key="standardLoft" className="t-fontWeight--normal">High Loft</span>,
            (<ul key="highLoftList" className={styles.discList}>
                <li>Roughly 6-7” high</li>
                <li>Taller pillow height preferred by side & combination sleepers or anyone who prefers a plusher pillow</li>
            </ul>)
        ],
        imageFolder: 'products/the-saatva-pillow/loft-comparison',
        imageName: 'saatva-latex-pillow-loft-comparison-1-1.jpg',
        imageAlt: 'Loft comparison saatva latex pillow'
    },
    [`${productCodes.THE_SAATVA_PILLOW}-premiumPillow`]: {
        title: `A premium pillow from the inside out`,
        content: () => [
            <ProductAccordion contentKey={productCodes.THE_SAATVA_PILLOW} key="saatvaPremiumPillow" classNameModifier={'bgContrast1'} className={styles.tanAccordion} />
        ],
        imageFolder: 'products/the-saatva-pillow/diagram',
        imageName: 'saatva-latex-pillow-diagram-1-1.jpg',
        imageAlt: 'Saatva latex pillow diagram'
    },
    [`${productCodes.THE_SAATVA_PILLOW}--why-natural`]: {
        title: `Why natural latex?`,
        content: () => [
            'There’s nothing quite like the buoyant, pressure-free support of natural latex. Our latex goes through a washing process to remove all allergy-causing proteins, so all you’re left with is pure, lasting comfort.',
            <ProductAccordion contentKey={`${productCodes.THE_SAATVA_PILLOW}--why-natural`} key="memoryFoamAccordion" />
        ],
        imageFolder: 'generic/why-natural-latex',
        imageName: 'why-natural-latex-1-1.jpg',
        imageAlt: 'Natural latex mattress material'
    },
    [`${productCodes.ORGANIC_QUILTED_PILLOW}`]: {
        title: `A premium pillow from the inside out`,
        content: () => [
            <ProductAccordion contentKey={productCodes.ORGANIC_QUILTED_PILLOW} key="saatvaPremiumPillow" classNameModifier={'bgContrast1'} className={styles.tanAccordion} />
        ],
        imageFolder: 'products/organic-quilted-pillow/diagram',
        imageName: 'organic-quilted-pillow-diagram-1-1.jpg',
        imageAlt: 'Organic Quilted pillow diagram'
    },
    [`${productCodes.ORGANIC_QUILTED_PILLOW}--why-natural`]: {
        title: `Why natural latex?`,
        content: () => [
            'There’s nothing quite like the buoyant, pressure-free support of natural latex. Our latex goes through a washing process to remove all allergy-causing proteins, so all you’re left with is pure, lasting comfort.',
            <ProductAccordion contentKey={`${productCodes.THE_SAATVA_PILLOW}--why-natural`} key="memoryFoamAccordion" />
        ],
        imageFolder: 'generic/why-natural-latex',
        imageName: 'why-natural-latex-1-1.jpg',
        imageAlt: 'Natural latex mattress material'
    },
    [`${productCodes.MEMORY_FOAM_PILLOW}`]: {
        title: `A premium pillow from the inside out`,
        content: () => [
            <ProductAccordion contentKey={productCodes.MEMORY_FOAM_PILLOW} key="saatvaPremiumPillow" classNameModifier={'bgContrast1'} className={styles.tanAccordion} />
        ],
        imageFolder: 'products/memory-foam-pillow/diagram',
        imageName: 'graphite-memory-foam-pillow-diagram-1-1.jpg',
        imageAlt: 'Memory foam pillow diagram'
        
    },
    [`${productCodes.DOWN_ALTERNATIVE_PILLOW}`]: {
        title: `A premium pillow from the inside out`,
        content: () => [
            <ProductAccordion contentKey={productCodes.DOWN_ALTERNATIVE_PILLOW} key="saatvaPremiumPillow" classNameModifier={'bgContrast1'} className={styles.tanAccordion} />
        ],
        imageFolder: 'products/down-alternative-pillow/diagram',
        imageName: 'down-alternative-pillow-diagram-1-1.jpg',
        imageAlt: 'Down alternative pillow diagram'
    },
    [`${productCodes.CLOUD_MEMORY_FOAM_PILLOW}`]: {
        title: `A premium pillow from the inside out`,
        content: () => [
            <ProductAccordion contentKey={productCodes.CLOUD_MEMORY_FOAM_PILLOW} key="saatvaPremiumPillow" classNameModifier={'bgContrast1'} className={styles.tanAccordion} />
        ],
        imageFolder: 'products/cloud-memory-foam-pillow/diagram',
        imageName: 'cloud-memory-foam-pillow-diagram-1-1.jpg',
        imageAlt: 'Cloud memory pillow diagram'
    },
    [productCodes.VELVET_PICK_STICH_QUILT]: {
        title: `3 ways to enjoy your velvet pick stitch quilts`,
        content: () => [
            <ProductAccordion contentKey={productCodes.VELVET_PICK_STICH_QUILT} key="velvetPickStichAccordion" classNameModifier='bgContrast2' className='u-bgColor--contrast-2'/>
        ],
        imageFolder: 'products/velvet-pick-stitch-quilt/lifestyle/terra',
        imageName: 'velvet-pick-stitch-quilt-lifestyle-terra-1-1.jpg',
        imageAlt: 'Velvet Pick Stitch Quilt'
    },
    [`${productCodes.DOWN_ALTERNATIVE_COMFORTER}`]: {
        title: `Stay comfy, cozy & cool`,
        content: () => [
            'Our naturally breathable, moisture-wicking organic cotton shell and cloud-like down alternative and lyocell fill keeps you warm without overheating.'
        ],
        imageFolder: 'products/down-alternative-comforter/detail',
        imageName: 'down-alternative-comforter-detail-1-1.jpg',
        imageAlt: 'All-Year Comforter'
    },
    [`${productCodes.BANDED_PERCALE_DUVET_SET}`]: {
        title: `Keep it cool in percale cotton`,
        content: () => [
            'Woven in a classic one-yarn-over, one-yarn-under pattern with an ultra-breathable 200 thread count, percale feels as smooth and crisp as your favorite button down shirt—only better.'
        ],
        imageFolder: 'generic/material-percale',
        imageName: 'material-percale-1-1.jpg',
        imageAlt: 'banded percale duvet set'
    },
    [`${productCodes.EMBROIDERED_SATEEN_DUVET_SET}`]: {
        title: `Indulge in the luxury of sateen cotton`,
        content: () => [
            'Woven in a one-yarn-over, three-yarn-under pattern with a silky 300 thread count, sateen feels heavenly soft against the skin.'
        ],
        imageFolder: 'generic/material-sateen',
        imageName: 'material-sateen-1-1.jpg',
        imageAlt: 'embroidered sateen duvet set'
    },
    [`${productCodes.ESSENTIAL_DUVET_COVER_SET}`]: {
        title: `Breathable cotton-viscose blend`,
        content: () => [
            'This exceptionally soft and breathable fabric blend is perfect for hot sleepers.'
        ],
        imageFolder: 'generic/material-essential',
        imageName: 'material-essential-1-1.jpg',
        imageAlt: 'essential duvet over set'
    },
    [`${productCodes.SIGNATURE_DUVET_COVER_SET}`]: {
        title: `Indulge in the luxury of sateen cotton`,
        content: () => [
            'Woven in a one-yarn-over, three-yarn-under pattern with a silky 300 thread count, sateen feels heavenly smooth against the skin.'
        ],
        imageFolder: 'generic/material-sateen',
        imageName: 'material-sateen-1-1.jpg',
        imageAlt: 'signature duvet cover set'
    },
    [`${productCodes.HEAVYWEIGHT_COMFORTER}`]: {
        title: `Our warmest, coziest comforter yet`,
        content: () => [
            'Our irresistibly plush & weighty 420 GSM (grams per meter) cotton shell is filled with thicker layers of our breathable, cloud-like down alternative & lyocell blend. It’s designed to keep you warm and comfy through the coldest nights.'
        ],
        imageFolder: 'products/heavyweight-comforter/detail',
        imageName: 'heavyweight-comforter-detail-1-1.jpg',
        imageAlt: 'Heavyweight Comforter'
    },
    [`${productCodes.LIGHTWEIGHT_DOWN_ALTERNATIVE_COMFORTER}`]: {
        title: `Stay comfy, cozy & cool`,
        content: () => [
            'Our naturally breathable, moisture-wicking organic cotton shell and cloud-like down alternative and lyocell fill keeps you warm without overheating.'
        ],
        imageFolder: 'products/lightweight-down-alternative-comforter/detail',
        imageName: 'lightweight-down-alternative-comforter-detail-1-1.jpg',
        imageAlt: 'lightweight down alternative comforter'
    },
    [`${productCodes.PERCALE_DUVET_COVER_SET}`]: {
        title: `Keep it cool in percale cotton`,
        content: () => [
            'Woven in a classic one-yarn-over, one-yarn-under pattern with an ultra-breathable 200 thread count, percale feels as smooth and crisp as your favorite button down shirt—only better.'
        ],
        imageFolder: 'generic/material-percale',
        imageName: 'material-percale-1-1.jpg',
        imageAlt: 'percale duvet cover set'
    },
    [`${productCodes.ORGANIC_SATEEN_DUVET_COVER_SET}`]: {
        title: `Indulge in the luxury of sateen cotton`,
        content: () => [
            'Woven in a one-yarn-over, three-yarn-under pattern with a silky 300 thread count, sateen feels heavenly soft against the skin.'
        ],
        imageFolder: 'generic/material-sateen',
        imageName: 'material-sateen-1-1.jpg',
        imageAlt: 'organic sateen duvet cover set'
    },
    [`${productCodes.WAFFLE_KNIT_DUVET_COVER_SET}`]: {
        title: `Breathable yet insulating waffle weave`,
        content: () => [
            'This unique three-dimensional weave helps retain warmth without ever feeling stuffy.'
        ],
        imageFolder: 'products/waffle-knit-duvet-cover-set/detail/white',
        imageName: 'waffle-knit-duvet-cover-set-detail-white-1-1.jpg',
        imageAlt: 'waffle knit duvet cover set'
    },
    [`${productCodes.LINEN_DUVET_COVER_SET}`]: {
        title: ` Upgrade to the distinctive comfort of linen`,
        content: () => [
            'Our 100% European flax linen is yarn dyed and prewashed for softness that only gets better with time.'
        ],
        imageFolder: 'generic/material-linen',
        imageName: 'material-linen-1-1.jpg',
        imageAlt: 'linen duvet cover set'
    },
    'worthTheSplurge': {
        title: 'Why linen bedding is worth the splurge',
        content: () => [
            'Although linen can cost more initially, here are a few reasons why it’s a luxurious alternative to cotton sheets.',
            <ProductAccordion contentKey={'worthTheSplurge'} key="worthTheSplurge" className={styles.tanAccordion} />
        ],
        imageFolder: 'products/linen-sheet-set/lifestyle-angle/ivory',
        imageName: 'linen-sheet-set-lifestyle-angle-ivory-1-1.jpg',
        imageAlt: 'Why linen bedding is worth the splurge'
    },
    [`${productCodes.PERCALE_SHEET_SET}`]: {
        title: `Keep it cool in percale cotton`,
        content: () => [
            'Woven in a classic one-yarn-over, one-yarn-under pattern with an ultra-breathable 200 thread count, percale feels as smooth and crisp as your favorite button down shirt—only better.'
        ],
        imageFolder: 'generic/material-percale',
        imageName: 'material-percale-1-1.jpg',
        imageAlt: 'percale sheet set'
    },
    [`${productCodes.ORGANIC_COTTON_SHEETS}`]: {
        title: `Indulge in the luxury of sateen cotton`,
        content: () => [
            'Woven in a one-yarn-over, three-yarn-under pattern with a silky 300 thread count, sateen feels heavenly soft against the skin.'
        ],
        imageFolder: 'generic/material-sateen',
        imageName: 'material-sateen-1-1.jpg',
        imageAlt: 'organic cotton sheets'
    },
    [`${productCodes.SATEEN_COTTON_SHEET_SET}`]: {
        title: `Indulge in the luxury of sateen cotton`,
        content: () => [
            'Woven in a one-yarn-over, three-yarn-under pattern with a silky 300 thread count, sateen feels heavenly soft against the skin.'
        ],
        imageFolder: 'generic/material-sateen',
        imageName: 'material-sateen-1-1.jpg',
        imageAlt: 'sateen cotton sheet set'
    },
    [`${productCodes.FLANNEL_SHEET_SET}`]: {
        title: `Velvety-soft cotton flannel`,
        content: () => [
            'Brushed and yarn-dyed for ultimate softness, our luxury flannel offers substantial coziness without the weight.'
        ],
        imageFolder: 'products/flannel-sheet-set/lifestyle-close-up/sand',
        imageName: 'flannel-sheet-set-lifestyle-close-up-sand-1-1.jpg',
        imageAlt: 'flannel sheet set'
    },
    [`${productCodes.LINEN_SHEET_SET}`]: {
        title: ` Upgrade to the distinctive comfort of linen`,
        content: () => [
            'Our 100% European flax linen is yarn dyed and prewashed for softness that only gets better with time.'
        ],
        imageFolder: 'generic/material-linen',
        imageName: 'material-linen-1-1.jpg',
        imageAlt: 'linen sheet set'
    },
    [`${productCodes.ESSENTIAL_SHEET_SET}`]: {
        title: `Breathable cotton-viscose blend`,
        content: () => [
            'This exceptionally soft and breathable fabric blend is perfect for hot sleepers.'
        ],
        imageFolder: 'generic/material-essential',
        imageName: 'material-essential-1-1.jpg',
        imageAlt: 'essential duvet over set'
    },
    [`${productCodes.EMBROIDERED_HOTEL_STYLE_SHEETS}`]: {
        title: `Indulge in the luxury of sateen cotton`,
        content: () => [
            'Woven in a one-yarn-over, three-yarn-under pattern with a silky 300 thread count, sateen feels heavenly soft against the skin.'
        ],
        imageFolder: 'generic/material-sateen',
        imageName: 'material-sateen-1-1.jpg',
        imageAlt: 'embroidered hotel style sheets'
    },
    [`${productCodes.BANDED_PERCALE_SHEET_SET}`]: {
        title: `Keep it cool in percale cotton`,
        content: () => [
            'Woven in a classic one-yarn-over, one-yarn-under pattern with an ultra-breathable 200 thread count, percale feels as smooth and crisp as your favorite button down shirt—only better.'
        ],
        imageFolder: 'generic/material-percale',
        imageName: 'material-percale-1-1.jpg',
        imageAlt: 'banded percale duvet set'
    },
    [`${productCodes.ORGANIC_VELVET_QUILT}`]: {
        title: `3 ways to enjoy your cotton velvet quilt`,
        content: () => [
            <ProductAccordion contentKey={productCodes.ORGANIC_VELVET_QUILT} key="organicVelvetQuilt" classNameModifier={'bgContrast1'} className={styles.tanAccordion} />
        ],
        imageFolder: 'products/organic-velvet-quilt/messy/taupe',
        imageName: 'organic-velvet-quilt-messy-taupe-1-1.jpg',
        imageAlt: 'organic velvet quilt messy in bed'
    },
    [`${productCodes.ORGANIC_COTTON_CHANNEL_QUILT}`]: {
        title: `3 ways to enjoy your cotton quilt`,
        content: () => [
            <ProductAccordion contentKey={productCodes.ORGANIC_COTTON_CHANNEL_QUILT} key="organicVelvetQuilt" classNameModifier={'bgContrast1'} className={styles.tanAccordion} />
        ],
        imageFolder: 'products/organic-cotton-channel-quilt/messy/grey',
        imageName: 'organic-cotton-channel-quilt-messy-grey-1-1.jpg',
        imageAlt: 'organic cotton quilt messy in bed'
    },
    [`${productCodes.AERO_QUILT}`]: {
        title: `3 ways to enjoy your lightweight quilt`,
        content: () => [
            <ProductAccordion contentKey={productCodes.AERO_QUILT} key="organicVelvetQuilt" classNameModifier={'bgContrast1'} className={styles.tanAccordion} />
        ],
        imageFolder: 'products/aero-quilt/messy/sand/',
        imageName: 'aero-quilt-messy-sand-1-1.jpg',
        imageAlt: 'aero quilt messy in bed'
    },
    [`${productCodes.WEIGHTED_BLANKET}`]: {
        title: `Three ways to enjoy your weighted blanket`,
        content: () => [
            <ProductAccordion contentKey={productCodes.WEIGHTED_BLANKET} key="organicVelvetQuilt" classNameModifier={'bgContrast1'} className={styles.tanAccordion} />
        ],
        imageFolder: 'products/weighted-blanket/on-sofa-draped/graphite',
        imageName: 'weighted-blanket-on-sofa-draped-graphite-1-1.jpg',
        imageAlt: 'weighted blanked on sofa'
    },
    [`${productCodes.WAFFLE_KNIT_BLANKET}`]: {
        title: `Three ways to enjoy your waffle knit cotton blanket`,
        content: () => [
            <ProductAccordion contentKey={productCodes.WAFFLE_KNIT_BLANKET} key="organicVelvetQuilt" classNameModifier={'bgContrast1'} className={styles.tanAccordion} />
        ],
        imageFolder: 'products/waffle-knit-blanket/lifestyle-chair/taupe',
        imageName: 'waffle-knit-blanket-lifestyle-chair-taupe-1-1.jpg',
        imageAlt: 'weighted blanked on sofa'
    },
    [`${productCodes.SWEATER_KNIT_BLANKET}`]: {
        title: `Three ways to enjoy your sweater knit cotton blanket`,
        content: () => [
            <ProductAccordion contentKey={productCodes.SWEATER_KNIT_BLANKET} key="organicVelvetQuilt" classNameModifier={'bgContrast1'} className={styles.tanAccordion} />
        ],
        imageFolder: 'products/sweater-knit-blanket/lifestyle-chair/terra',
        imageName: 'sweater-knit-blanket-lifestyle-chair-terra-1-1.jpg',
        imageAlt: 'sweater knit blanked on sofa'
    },
    [`${productCodes.HERRINGBONE_KNIT_BLANKET}`]: {
        title: `Three ways to enjoy your knit cotton blanket`,
        content: () => [
            <ProductAccordion contentKey={productCodes.HERRINGBONE_KNIT_BLANKET} key="organicVelvetQuilt" classNameModifier={'bgContrast1'} className={styles.tanAccordion} />
        ],
        imageFolder: 'products/herringbone-knit-blanket/messy/slate',
        imageName: 'herringbone-knit-blanket-messy-slate-1-1.jpg',
        imageAlt: 'herringbone knit on sofa'
    },
    [`${productCodes.DIAMOND_KNIT_BLANKET}`]: {
        title: `Three ways to enjoy your knit cotton blanket`,
        content: () => [
            <ProductAccordion contentKey={productCodes.DIAMOND_KNIT_BLANKET} key="organicVelvetQuilt" classNameModifier={'bgContrast1'} className={styles.tanAccordion} />
        ],
        imageFolder: 'products/diamond-knit-blanket/colorways-messy',
        imageName: 'diamond-knit-blanket-colorways-messy-1-1.jpg',
        imageAlt: 'weighted blanked on sofa'
    },
    [productCodes.SILK_PILLOWCASE]: {
        title: `Why upgrade to a silk pillowcase?`,
        content: () => [
            <ProductAccordion contentKey={productCodes.SILK_PILLOWCASE} key="silkPillowcaseAccordion" classNameModifier='bgContrast2' className='u-bgColor--contrast-2'/>
        ],
        imageFolder: 'products/silk-pillowcase/lifestyle/graphite',
        imageName: 'silk-pillowcase-lifestyle-graphite-1-1.jpg',
        imageAlt: 'Silk Pillowcase'
    },
}
export { config }
